.prefilledAnswer {
    @apply type-090;

    display: flex;
    justify-content: space-between;
    padding: theme('fields.padding.default');
    gap: theme('spacing.050');
    border-radius: theme('fields.borderRadius.default');
    background-color: theme('colors.layers.canvas');
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset;

    pre {
        white-space: pre-wrap;
    }
}
